<template>
  <div
    class="chat-contact d-flex align-center pa-3 cursor-pointer rounded-lg"
    :class="{'bg-gradient-primary active-chat-contact': isActive}"
    v-on="$listeners"
  >
    <v-badge
      bottom
      overlap
      dot
      bordered
      offset-x="11"
      offset-y="11"
      class="me-3 user-status-badge"
      :value="chatContacts"
    >
      <v-avatar
        size="38"
        class="v-avatar-light-bg"

      >
        <v-img
          v-if="user.profile_picture"
          :src="user.profile_picture"
        ></v-img>
        <span v-else>{{ avatarText(user.name) }}</span>
      </v-avatar>
    </v-badge>
    <div class="flex-grow-1 overflow-hidden">
      <p class="mb-0 font-weight-medium text--primary">
        {{ user.name }}
      </p>
      <p class="text--disabled mb-0 text-truncate">
        test
        <!-- {{ chatContacts ? user.chat.lastMessage.message : user.about }} -->
      </p>
    </div>
    <div
      v-if="chatContacts"
      class="chat-meta d-flex flex-column align-self-stretch align-end"
    >
      <p class="text-no-wrap mb-0">
        <!-- {{ formatDateToMonthShort(user.chat.lastMessage.time, { hour: 'numeric', minute: 'numeric' }) }} -->
      </p>
      <v-badge
        color="error"
        inline
        class="number-badge"
      >
      </v-badge>
    </div>
  </div>
</template>

<script>
import { formatDateToMonthShort, avatarText } from '@core/utils/filter'
import useChat from './useChat'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    chatContacts: {
      type: Array,
      // default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  // setup() {
  //   const { resolveAvatarBadgeVariant } = useChat()

  //   return { formatDateToMonthShort, resolveAvatarBadgeVariant, avatarText }
  // },
}
</script>

<style>
</style>
